<template>
  <div class="body">
    <article class="banner">
      <div class="layout">
        <h2>新能源汽车<strong>整车设计智造</strong></h2>
        <p class="slogan">为智慧城市协同赋能</p>
        <img class="img" src="../../assets/v4/about/banner.jpg" width="1920" height="1010" />
        <h3>“<strong>橙仕</strong>”与<strong>城市</strong>、<strong>成事</strong>谐音，也有<strong>乘势出发、勇于尝试</strong>的意思</h3>
        <p>橙仕汽车专注于末端配送市场，打造全场景末端配送服务</p>
        <p>让每个用户都能享受全新的配送体验，让每个配送小哥享受美好生活</p>
        <p class="values">
          <span>使命：<strong>碳中和时代新能源物流末端配送的开创者</strong></span>
          <span class="separator"></span>
          <span>愿景：<strong>让每个配送小哥享受美好生活</strong></span>
        </p>
        <ul class="contacts">
          <li>
            <h4>矩阵新起点数据技术（上海）有限公司</h4>
            <p>上海市杨浦区政恒路66号凯迪立方大厦2号楼8楼</p>
          </li>
          <li>
            <h4>山东豪驰智能汽车有限公司</h4>
            <p>山东省济南市莱芜区杨庄镇新材料产业园</p>
          </li>
          <li class="tel"><a href="tel:4007991212">
            <img src="../../assets/v4/about/tel.png" width="386" height="62" alt="400 799 1212" />
          </a></li>
        </ul>
      </div>
    </article>

    <div class="aboutLayout">
      <article class="about">
        <h3><strong>橙仕集团</strong></h3>
        <section class="con">
          <p>橙仕集团是一家全球领先的商用车造车新势力，是末端配送智能商用车开创引领者。其推出的橙仕01平台系列车型开创了末端配送赛道的场景最佳产品，填补了国内空白，是末端物流高效、合法、绿色智能化车辆赛道的定义者。</p>
          <p>橙仕集团致力于推动全球末端物流的绿色能源化和智能化，通过技术创新为每一位配送小哥提供高性价比的纯电动车辆；通过数据、计算能力、算法和应用场景这四大要素的整合，驱动末端物流的智能化发展。橙仕集团由旗下智能商用车研发智造企业豪驰智能汽车、人工智能及互联网科技企业矩阵新起点、智能汽车物联网科技企业亿点灵感、绿色能源智慧交通共享服务企业豪驰运通四大业务板块组成。</p>
          <!-- <p>矩阵数据在山东设有占地500多亩的智能汽车生产基地【矩阵-豪驰基地】，四大工艺齐全，具备年产10万台的工业制造能力。</p> -->

          <i class="dot"></i>
        </section>
      </article>

      <article class="history">
        <h3><strong>橙仕集团</strong>发展历程</h3>
        <ol class="timeline">
          <li class="main">
            <h4><strong>2023</strong>年</h4>
            <ul class="points">
              <li>
                <span class="t">11月</span>
                <span class="txt">签约世界散打冠军、两届中国功夫王黄磊，综合格斗世界冠军、中国第一条世界综合格斗金腰带获得者王赛为品牌代言人</span>
              </li>
              <li class="inset">
                <span class="t">10月</span>
                <span class="txt">橙仕汽车完成10亿B轮融资，由全球领先的物流巨头普洛斯旗下隐山资本领投，运力世界第一的中国远洋海运集团旗下远致投资、赣锋锂业及中享聚盛联合投资完成</span>
              </li>
              <li>
                <span class="t">9月</span>
                <span class="txt">由橙仕01改造的“亚运会流动观赛空间”及数百辆橙仕X2化身的圆通速递用车亮相杭州第19届亚运会</span>
              </li>
              <li>
                <span class="t">7月</span>
                <span class="txt">橙仕汽车获评“山东省瞪羚企业”称号</span>
              </li>
              <li>
                <span class="t">6月</span>
                <span class="txt">橙仕汽车被认定为济南市工业企业“一企一技术”研发中心</span>
              </li>
              <li>
                <span class="t">4月</span>
                <span class="txt">橙仕汽车和驭势科技达成战略合作 推动快递末端无人配送向规模商业化落地</span>
              </li>
              <li>
                <span class="t">3月</span>
                <span class="txt">百台橙仕新能源物流车出口多米尼加</span>
              </li>
              <li>
                <span class="t">2月</span>
                <span class="txt">官宣ATOM计划：造新一代智能物流车，售价1.88万</span>
              </li>
            </ul>
            <h4><strong>2022</strong>年</h4>
            <ul class="points">
              <li>
                <span class="t">11月</span>
                <span class="txt">橙仕汽车健康防疫全系列产品亮相第五届中国国际进口博览会</span>
              </li>
              <li>
                <span class="t">6月</span>
                <span class="txt">橙仕汽车荣获山东省“专精特新”中小企业称号</span>
              </li>
            </ul>
            <h4><strong>2021</strong>年</h4>
            <ul class="points">
              <li>
                <span class="t">12月27日</span>
                <span class="txt">矩阵数据科技与中通快递联合华办零载汽车-开拓者号应用场景内测，加速矩阵数据科技零载快递无人车商业落地，引发投资者高度关注</span>
              </li>
              <li class="inset">
                <span class="t">12月21日</span>
                <span class="txt">在2021华为智能汽车解决方案生态论坛上，矩阵数据科技发布首个机器人汽车品牌“零载汽车”，并亮相首款搭载华为MDC智能驾驶计算平台快递无人车“开拓者号”</span>
              </li>
              <li>
                <span class="t">12月16日</span>
                <span class="txt">矩阵数据科技与德国Weber家族签署战略合作协议，推动矩阵数据科技快递无人车在德国和欧洲落地，并布局无人运力网络</span>
              </li>
              <li>
                <span class="t">9月25日-28日</span>
                <span class="txt">矩阵数据科技参加“2021世界智能网联汽车大会暨智能网联汽车展会”，井参加首届全国眢能驾驶测试赛（总决赛），矩阵数据科技无人车驾驶快递物流车受到央视新闻直播重点关注</span>
              </li>
              <li>
                <span class="t">9月18日</span>
                <span class="txt">矩阵数据科技成功举办“济南市智能网联汽车道路测试一新能源快递无人车技术测试”启动仪式；受到新华社、山东卫视、中国网、财联社等权威媒体高度关注</span>
              </li>
              <li>
                <span class="t">5月21日</span>
                <span class="txt">2021世界智能驾驶挑战赛在天津东丽湖开赛，矩阵数据科技自主研发的快递无人车橙仕01D开发者版正式亮相本届赛事</span>
              </li>
            </ul>
          </li>
          <li>
            <h4><strong>2020</strong>年</h4>
            <ul class="points">
              <li>
                <span class="t">12月</span>
                <span class="txt">橙仕01下线仪式暨橙仕合伙人大会</span>
              </li>
              <li>
                <span class="t">5月</span>
                <span class="txt">作为优秀代表项目荣登CCTV《新闻联播》报道</span>
              </li>
              <li>
                <span class="t">2月</span>
                <span class="txt">先后列入济南市、山东省重点项目(年度第一名)</span>
              </li>
            </ul>
          </li>
          <li>
            <h4><strong>2019</strong>年</h4>
            <ul class="points">
              <li>
                <span class="t">12月</span>
                <span class="txt">完成 A轮融资</span>
              </li>
              <li>
                <span class="t">5月</span>
                <span class="txt">列入《山东省新旧动能转换重大项目库第二批优选项目》</span
                >
              </li>
              <li>
                <span class="t">4月</span>
                <span class="txt">获批整车资质</span>
                <span class="txt">一汽解放 全面合作</span>
              </li>
            </ul>
          </li>
          <li>
            <h4><strong>2018</strong>年</h4>
            <ul class="points">
              <li>
                <span class="txt">矩阵工业成立，项目落地山东济南</span>
              </li>
            </ul>
          </li>
          <li>
            <h4><strong>2012</strong>年</h4>
            <ul class="points">
              <li>
                <span class="txt">矩阵汽车-上海豪骋科技成立<br class="mHide" />主要发展方向为新能源汽车技术、汽车资质技术</span>
              </li>
            </ul>
          </li>
        </ol>
      </article>
    </div>

    <section class="map">
      <header class="hd">
        <h3><strong>橙仕</strong>全球布局</h3>
        <p>围绕<strong>新能源</strong>、<strong>新技术</strong>、<strong>新业态</strong>进行全球布局</p>
      </header>
      <ul class="places">
        <li class="p1">
          <dl class="c1">
            <dt>中国·<strong>北京</strong></dt>
            <dd>
              <p>产业政策解读技术服务</p>
              <h4>中联豪骋高新科技有限公司</h4>
            </dd>
          </dl>
          <dl class="c2">
            <dt>中国·<strong>山东</strong></dt>
            <dd>
              <p>亿点灵感汽车科技(山东)有限公司</p>
              <p>山东豪驰智能汽车有限公司</p>
              <p>矩阵运通科技有限公司</p>
              <p>矩阵优选山东科技信息有限公司</p>
            </dd>
          </dl>
          <dl class="c3">
            <dt>中国·<strong>上海</strong></dt>
            <dd>
              <p>智能与新能源整车技术开发</p>
              <p>矩阵新起点数据技术（上海）有限公司</p>
              <p>上海交大-豪骋研发中心</p>
              <p>浙江大学-豪骋开发中心</p>
            </dd>
          </dl>
        </li>
        <li class="p2">
          <dl class="c1">
            <dt>中国·<strong>四川</strong></dt>
            <dd>
              <h4>四川矩阵北斗数据科技有限公司</h4>
            </dd>
          </dl>
          <dl class="c2">
            <dt>中国·<strong>重庆</strong></dt>
            <dd>
              <p>新材料研发制造</p>
              <h4>重庆泰奥汽车科技有限公司</h4>
            </dd>
          </dl>
        </li>
        <li class="p3">
          <dl class="c1">
            <dt>德国·<strong>法兰克福</strong></dt>
            <dd>
              <p>豪骋科技欧洲公司</p>
              <h4>ALLCHEER EUROPE GmbH</h4>
            </dd>
          </dl>
        </li>
        <li class="p4">
          <dl class="c1">
            <dt>意大利·<strong>都灵</strong></dt>
            <dd>
              <p>整车造型设计</p>
              <h4>矩阵-BDI全球设计中心</h4>
            </dd>
          </dl>
        </li>
        <li class="p5">
          <dl class="c1">
            <dt>西班牙·<strong>巴塞罗那</strong></dt>
            <dd>
              <p>轻量化技术开发</p>
              <h4>Prome Theos轻量化技术中心</h4>
            </dd>
          </dl>
        </li>
      </ul>
    </section>

    <section class="ecology">
      <h3>橙仕集团<strong>生态布局</strong></h3>
      <ul>
        <li>
          <h4>整车智能制造</h4>
          <p>山东豪驰智能汽车有限公司</p>
        </li>
        <li>
          <h4>人工智能 互联网科技</h4>
          <p>矩阵新起点数据技术(上海)有限公司</p>
        </li>
        <li>
          <h4>智能汽车物联网</h4>
          <p>亿点灵感汽车科技(山东)有限公司</p>
        </li>
        <li>
          <h4>绿色能源智慧交通共享服务</h4>
          <p>豪驰运通汽车科技(山东)有限公司<br />上海豪骋科技有限公司</p>
        </li>
      </ul>
    </section>

    <section class="factoryBanner">
      <h2><strong>豪驰智能汽车</strong></h2>
      <img class="img" src="../../assets/v3/industrial/banner.png" width="1920" height="730" />
      <p>山东豪驰智能汽车有限公司（简称豪驰智能）是由齐鲁财金投资集团有限公司、矩阵数据科技（上海）有限公司在济南市莱芜区建立具有核心自主知识产权的全国第一个基于5G场景无人驾驶大批量整车智造基地。豪驰智能作为山东省第二批新旧动能转换重点项目、济南市重点项目、山东省重大项目，全力建设智慧工厂和5G车联网。专注于研发快递车、无人物流车，是首个拥有国家备案整车生产资质的智能驾驶企业。</p>
    </section>

    <section class="box factory">
      <div class="layout">
        <img class="factoryMap" src="../../assets/v3/industrial/plan.png" width="898" height="725" />
        <div class="summary">
          <h3>豪驰智能工厂的<strong>价值创造</strong>与<strong>生产</strong></h3>
          <p>我们的所有设施实现碳中和。在致力于达到清洁 Smart Factory（智能工厂）的路上，我们在各个领域尝试利用最新技术。践行循环经济原理和符合环境标准的供应链，进而完善我们的可持续发展战略。</p>
          <img class="img" src="../../assets/v3/industrial/factory.jpg" width="726" height="407" />
        </div>
      </div>
    </section>

    <section class="box intro">
      <div class="layout">
        <h3>我们是一家拥有<strong>整车制造能力</strong>的<strong>智能驾驶企业</strong></h3>
        <p>矩阵数据在山东设有占地500多亩的智能汽车生产基地【矩阵-豪驰基地】，四大工艺齐全，具备年产10万台的工业制造能力。</p>
        <ul class="workshops">
          <li>
            <div class="con">
              <h4>冲压车间</h4>
              <p>采用更先进的冲压设备和模具供应商自动化率达到100%。</p>
            </div>
            <div class="preview">
              <img class="img" src="../../assets/v3/industrial/workshop_1.jpg" width="980" height="418" />
            </div>
          </li>
          <li>
            <div class="con">
              <h4>焊装车间</h4>
              <p>采用包含激光焊接在内的10种焊接工艺点焊、铆接自动化率达到100%。</p>
            </div>
            <div class="preview">
              <img class="img" src="../../assets/v3/industrial/workshop_2.jpg" width="980" height="418" />
            </div>
          </li>
          <li>
            <div class="con">
              <h4>涂装车间</h4>
              <p>使用更高质量的涂料供应商，喷涂自动化率100%。</p>
            </div>
            <div class="preview">
              <img class="img" src="../../assets/v3/industrial/workshop_3.jpg" width="980" height="418" />
            </div>
          </li>
          <li>
            <div class="con">
              <h4>总装车间</h4>
              <p>采用多种自动化安装设备，实时监测，保证快速准确的装配。</p>
            </div>
            <div class="preview">
              <img class="img" src="../../assets/v3/industrial/workshop_4.jpg" width="980" height="418" />
            </div>
          </li>
        </ul>
      </div>
    </section>
    
    <section class="box bannerEnd">
      <div class="layout">
        <div class="summary">
          <h3>环境的<strong>可持续性</strong>和<strong>责任感</strong></h3>
          <p>除了在质量、技术专长、性能和竞争力方面保持高标准之外，<br class="mHide" />矩阵还致力于要求供应商严格遵守可持续性发展要求。</p>
        </div>
        <img class="img" src="../../assets/v3/industrial/bannerEnd.jpg" width="1920" height="1284" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

<style scoped lang="scss">
.banner {
  padding-top: 300px;
  padding-bottom: 120px;
  font-size: 28px;
  line-height: 36px;
  // text-align: center;
  color: #fff;
  background-color: #e83e0b;

  .layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    position: relative;
    margin-bottom: 32px;
    font-size: 78px;
    line-height: 100px;
    color: #fff;
  }

  .slogan {
    position: relative;
    font-size: 48px;
  }

  .img {
    display: block;
    margin-top: -8.125%;
    max-width: 100%;
    height: auto;
  }

  h3 {
    margin-top: -12.8%;
    margin-bottom: 12px;
    font-size: 36px;
    line-height: 48px;
    color: #fff;
  }

  .values, .contacts {
    display: flex;
    width: 100%;
    max-width: 1280px;
    justify-content: space-between;
  }
  .values {
    margin: 60px 0 50px;
    align-items: center;
    padding: 20px 50px;
    font-size: 28px;
    line-height: 40px;
    color: #FFE0B6;
    border-top: 1px solid #FFE0B6;
    border-bottom: 1px solid #FFE0B6;

    .separator {
      height: 28px;
      width: 1px;
      background-color: #FFE0B6;
    }
  }

  .contacts {
    display: flex;

    li {
      position: relative;
      padding-top: 10px;
      padding-left: 30px;
      font-size: 20px;
      line-height: 28px;

      &::before {
        position: absolute;
        top: 10px;
        left: 0;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 24' width='24' height='24'%3E%3Cg fill='%23fff'%3E%3Cpath d='M10.107 24h1.786l6.885-6.439a9.822 9.822 0 0 0 0-14.548A11.324 11.324 0 0 0 11 0a11.324 11.324 0 0 0-7.778 3.013 9.822 9.822 0 0 0 0 14.548zm-5.1-19.317A8.723 8.723 0 0 1 11 2.362a8.724 8.724 0 0 1 5.992 2.321 7.567 7.567 0 0 1 0 11.208L11 21.495l-5.992-5.604a7.567 7.567 0 0 1 0-11.208z'/%3E%3Cpath d='M11 15.627a5.879 5.879 0 0 0 4.038-1.564 5.105 5.105 0 0 0 0-7.552 6.002 6.002 0 0 0-8.076 0 5.105 5.105 0 0 0 0 7.552A5.88 5.88 0 0 0 11 15.627zM8.153 7.624a4.232 4.232 0 0 1 5.694 0 3.6 3.6 0 0 1 0 5.326 4.227 4.227 0 0 1-5.694 0 3.6 3.6 0 0 1 0-5.326z'/%3E%3C/g%3E%3C/svg%3E");
      }

      &.tel {
        padding-top: 0;
        padding-left: 0;

        &::before {
          display: none;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      & + li {
        margin-left: 20px;
      }

      h4 {
        color: #fff;
        line-height: 24px;
        font-weight: bold;
        line-height: 28px;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .banner {
    .values {
      display: block;
      width: auto;

      .separator {
        display: none;
      }

      span {
        display: block;
        text-align: center;

        & + span {
          margin-left: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.aboutLayout {
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 1600px;
}

.about {
  margin: 150px 0;
  max-width: 1080px;

  h3 {
    margin-bottom: 20px;
    font-size: 80px;
    color: #e83e0b;
    line-height: 100px;
  }

  .con {
    position: relative;
    padding: 64px 64px 150px;
    font-size: 20px;
    line-height: 30px;
    border: 4px solid #e83e0b;

    p {
      padding: 12px 0;
      text-indent: 2em;
    }

    .dot {
      top: 12px;
      left: 46px;

      &, &::before, &::after {
        position: absolute;
        width: 17px;
        height: 17px;
        content: '';
        background-color: #ff9c00;
        border-radius: 50%;
      }

      &::before {
        top: 0;
        left: -29px;
        background-color: #e83e0b;
      }

      &::after {
        top: 0;
        right: -29px;
        background-color: #31b5a5;
      }
    }

    &::after {
      position: absolute;
      left: 80px;
      bottom: -50px;
      width: 275px;
      height: 175px;
      content: '';
      background: url(https://alihtml.juzhen.cn/juzhen/assets/img/about/0zai.png) no-repeat 0 0;
      background-size: contain;
    }
  }
}

.history {
  position: relative;
  margin: 0 0 150px 480px;
  width: calc(100% - 480px);
  max-width: 1080px;

  h3 {
    margin-bottom: 80px;
    position: relative;
    font-size: 60px;
    color: #e83e0b;
    line-height: 74px;
    z-index: 5;
  }
}

.timeline {
  border-left: 4px solid #e83e0b;

  &::after {
    display: block;
    width: 498px;
    height: 210px;
    content: '';
    background: url(https://alihtml.juzhen.cn/juzhen/assets/img/about/carend.png) no-repeat 0 100%;
    background-size: contain;
  }

  & > li {
    padding-left: 56px;
    font-size: 20px;
    line-height: 30px;

    &.main {
      position: relative;
      margin-top: -50px;
      margin-left: -4px;
      padding-left: 50%;
      padding-bottom: 42px;
      background-color: #fff;
      z-index: 1;

      &::before, &::after {
        position: absolute;
        bottom: 20px;
        left: 0;
        height: 24px;
        width: 30%;
        content: '';
      }

      &::before {
        transform: translateY(20px);
        border-top: 4px solid #e83e0b;
        border-left: 4px solid #e83e0b;
        border-top-left-radius: 24px;
      }
      &::after {
        left: 20%;
        border-bottom: 4px solid #e83e0b;
        border-right: 4px solid #e83e0b;
        border-bottom-right-radius: 24px;
      }

      h4 {
        margin-left: -100px;

        &::before, &::after {
          display: none;
        }
      }

      .points {
        margin-left: -2px;
        padding: 110px 0 20px;
        border-left: 4px solid #e83e0b;

        &:last-child {
          padding-bottom: 1px;

          li:last-child {
            &::before {
              position: absolute;
              left: -85%;
              bottom: -36px;
              content: '';
              width: 168px;
              height: 78px;
              background: url(../../assets/v4/about/icon_car2.png) no-repeat;
              background-size: contain;
            }
          }
        }

        li {
          position: relative;
          margin-top: -64px;
          padding-left: 46px;

          &.inset {
            &::before {
              position: absolute;
              left: 58px;
              top: -77px;
              content: '';
              width: 190px;
              height: 92px;
              background: url(../../assets/v4/about/icon_car.png) no-repeat;
              background-size: contain;
            }

            .t {
              padding-left: 220px;

              &::after {
                width: 250px;
              }
            }
          }

          .t {
            position: relative;
            font-style: italic;
            white-space: nowrap;

            strong {
              font-size: 45px;
              font-weight: normal;
            }

            &::before, &::after {
              position: absolute;
              top: 50%;
              content: "";
            }

            &::before {
              margin-top: -12px;
              left: -60px;
              width: 24px;
              height: 24px;
              border: 4px solid #e83e0b;
              border-radius: 50%;
              background-color: #fff;
            }

            &::after {
              margin-top: -2px;
              left: -36px;
              width: 30px;
              height: 4px;
              background-color: #e83e0b;
            }
          }

          .txt {
            min-height: 80px;
          }

          &:nth-child(2n - 1) {
            margin-left: -100%;
            padding-left: 0;
            width: calc(100% - 50px);
            text-align: right;

            .t {
              text-align: right;

              &::before {
                left: calc(100% + 36px);
              }
              &::after {
                left: calc(100% + 8px);
              }
            }
          }
        }
      }
    }

    h4 {
      position: relative;
      height: 48px;
      font-size: 24px;
      color: #e83e0b;
      font-style: italic;
      line-height: 48px;

      strong {
        font-size: 45px;
        font-weight: normal;
      }

      &::before, &::after {
        position: absolute;
        top: 50%;
        content: "";
      }

      &::before {
        margin-top: -8px;
        left: -70px;
        width: 24px;
        height: 24px;
        border: 4px solid #e83e0b;
        border-radius: 50%;
        background-color: #fff;
      }

      &::after {
        margin-top: 2px;
        left: -46px;
        width: 40px;
        height: 4px;
        background-color: #e83e0b;
      }
    }

    .points {
      margin-top: 8px;

      li {
        margin-bottom: 16px;

        .t {
          display: block;
          font-size: 26px;
          color: #e83e0b;
          line-height: 34px;
          font-style: italic;
        }

        .txt {
          display: block;
        }
      }
    }

    &:nth-child(3) {
      margin-top: -210px;
    }
    &:nth-child(4) {
      margin-top: -54px;
    }

    &:nth-child(2n) {
      margin-left: -540px;
      padding-left: 0;
      width: 480px;
      text-align: right;

      h4 {
        &::before {
          left: 526px;
        }
        &::after {
          left: 488px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .timeline {
    & >li.main {
      margin-top: 20px;
    }
  }
}

.map {
  position: relative;
  margin: 0 auto;
  max-width: 1920px;
  height: 800px;
  background: #e83e0b url(https://alihtml.juzhen.cn/juzhen/assets/img/about/layout.jpg) no-repeat 50% 50%;
  background-size: 1920px 800px;

  .hd {
    position: absolute;
    top: 226px;
    left: 50%;
    margin-left: -180px;
    font-size: 30px;
    line-height: 38px;
    color: #fff;
    z-index: 5;

    h3 {
      font-size: 60px;
      line-height: 64px;
      color: #fff;
    }
  }

  li {
    position: absolute;
    left: 50%;

    dl {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 22px;
      color: #fff;

      &::before, &::after {
        position: absolute;
        content: '';
        overflow: hidden;
        background-color: #fff;
      }

      &::before {
        width: 13px;
        height: 13px;
        border-radius: 50%;
      }

      dt {
        margin-bottom: 2px;
        font-size: 24px;
        line-height: 30px;
      }

      h4 {
        font-size: 16px;
        color: #fff;
        line-height: 22px;
        font-family: 'PingFang SC';
      }
    }

    &.p1 {
      top: 245px;
      margin-left: 490px;

      .c1 {
        &::before {
          top: 92px;
          left: -66px;
        }

        &::after {
          top: 98px;
          left: -490px;
          width: 430px;
          height: 1px;
        }
      }

      .c2 {
        &::before {
          top: 150px;
          left: -66px;
        }

        &::after {
          top: 98px;
          left: -60px;
          width: 1px;
          height: 58px;
        }
      }

      .c3 {
        &::before {
          top: 208px;
          left: -27px;
          box-shadow: 0 0 0 5px rgba($color: #fff, $alpha: .44),
            0 0 0 10px rgba($color: #fff, $alpha: .33);
        }

        &::after {
          top: 156px;
          left: -60px;
          width: 39px;
          height: 58px;
          background: none;
          border-left: 1px solid #fff;
          border-bottom: 1px solid #fff;
        }
      }
    }

    &.p2 {
      top: 442px;
      margin-left: 160px;

      .c1 {
        &::before {
          top: 10px;
          left: 134px;
        }

        &::after {
          top: 16px;
          left: 140px;
          width: 130px;
          height: 1px;
        }
      }

      .c2 {
        &::before {
          top: 10px;
          left: 170px;
        }
      }
    }

    &.p3 {
      top: 220px;
      margin-left: -480px;
      min-height: 120px;

      .c1 {
        &::before {
          left: -18px;
          top: 28px;
        }
        &::after {
          left: -12px;
          top: 34px;
          width: 1px;
          height: 90px;
        }
      }
    }

    &.p4 {
      top: 220px;
      margin-left: -760px;
      min-height: 120px;
      width: 220px;
      text-align: right;

      .c1 {
        &::before {
          top: 82px;
          left: 174px;
        }

        &::after {
          top: 88px;
          left: 180px;
          width: 1px;
          height: 36px;
        }
      }
    }

    &.p5 {
      top: 360px;
      margin-left: -760px;
      width: 220px;
      text-align: right;

      .c1 {
        &::before {
          top: -23px;
          left: 100px;
        }

        &::after {
          top: -17px;
          left: 106px;
          width: 655px;
          height: 1px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .map {
    background-size: 1632px auto;

    .places {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -720px;
      width: 1440px;
      height: 800px;
      transform: scale(.85);
    }
  }
}

.ecology {
  margin: 140px auto 180px;
  padding: 0 25px;
  max-width: 1300px;
  text-align: center;
  color: #e83e0b;
  font-size: 16px;
  line-height: 24px;

  h3 {
    font-size: 40px;
    color: #e83e0b;
    line-height: 80px;
  }  

  ul {
    display: flex;
    position: relative;
    padding-top: 70px;

    &::before, &::after {
      position: absolute;
      content: '';
      overflow: hidden;
      background-color: #e83e0b;
    }

    &::before {
      top: 0;
      left: 50%;
      margin-left: -6px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      box-shadow: 
        0 64px 0 0 rgba($color: #e83e0b, $alpha: 1),
        0 64px 0 5px rgba($color: #e83e0b, $alpha: .44),
        0 64px 0 10px rgba($color: #e83e0b, $alpha: .33);
    }
    &::after {
      top: 6px;
      left: 50%;
      width: 1px;
      height: 64px;
    }

    li {
      flex: 1;
      position: relative;
      padding: 70px 8px 0;

      &::before, &::after {
        position: absolute;
        content: '';
        overflow: hidden;
      }

      &::before {
        top: 45px;
        left: 50%;
        margin-left: -6px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #e83e0b;
      }
      &::after {
        top: 0;
        left: -50%;
        width: 100%;
        height: 51px;
        border-right: 1px solid;
        border-top: 1px solid;
        border-color: #e83e0b;
      }

      &:first-child {
        &::after {
          left: 50%;
          width: 1px;
        }
      }

      h4 {
        margin-bottom: 8px;
        font-size: 28px;
        line-height: 36px;
        color: #e83e0b;
      }
    }
  }
}

.factoryBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  width: 100%;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  overflow: hidden;
  background-color: #e83e0b;

  h2 {
    font-size: 80px;
    line-height: 140px;
    color: #fff;
    text-align: center;
  }

  p {
    position: relative;
    max-width: 1440px;
    padding: 0 20px;
    height: 260px;
    margin: -260px auto 0;
    text-indent: 2em;
    z-index: 5;
  }

  .img {
    position: relative;
    z-index: 1;
  }
}


.box {
  font-size: 18px;
  line-height: 28px;

  &::before {
    position: absolute;
    width: 12px;
    height: 175px;
    content: '';
    background-color: #ff3f00;
  }

  .layout {
    position: relative;
    max-width: 1440px;
  }

  h3 {
    font-size: 50px;
    line-height: 60px;
    // font-style: italic;
  }
}

.factory {
  padding: 160px 0 145px;
  background-color: #222226;

  &::before {
    right: 0;
  }

  .factoryMap {
    position: absolute;
    top: -72px;
    left: -240px;
  }

  .summary {
    margin-left: 675px;
    color: #fff;

    h3 {
      color: #fff;
    }

    p {
      margin: 12px 0 26px;
      text-indent: 2em;
    }

    .img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}

.intro {
  padding: 120px 0;

  &::before {
    left: 0;
  }

  h3 {
    margin-bottom: 8px;
  }

  .workshops {
    margin-top: 32px;

    li {
      display: flex;
      flex-direction: row-reverse;

      & + li {
        margin-top: 10px;
      }

      &:nth-child(2n) {
        flex-direction: row;

        .con {
          margin-left: 0;
          margin-right: 10px;
        }
      }

      .con {
        display: flex;
        padding: 50px;
        margin-left: 10px;
        flex-direction: column;
        justify-content: flex-end;
        width: 410px;
        color: #fff;
        background-color: #222226;

        h4 {
          font-size: 30px;
          color: #fff;
          line-height: 48px;
        }
      }

      .preview {
        flex: 1;

        .img {
          display: block;
          width: 100%;
          height: auto;
          min-height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.bannerEnd {
  margin-bottom: 120px;
  &::before {
    display: none;
  }

  .layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-height: 700px;
    overflow: hidden;
  }

  .summary {
    position: absolute;
    top: 100px;
    left: 120px;
  }

  h3 {
    margin-bottom: 16px;
    font-style: italic;
  }

  .img {
    display: block;
    // margin-top: -540px;
    width: 100%;
    height: auto;
    // min-height: 560px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 500px) {
  .banner {
    padding-top: 160px;
    padding-bottom: 60px;
    font-size: 12px;
    line-height: 16px;

    h2 {
      margin-bottom: 8px;
      font-size: 40px;
      line-height: 50px;
      text-align: center;

      strong {
        display: block;
      }
    }

    .slogan {
      font-size: 16px;
      line-height: 26px;
    }

    h3 {
      margin-top: -6%;
      max-width: 80%;
      margin-bottom: 6px;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }

    .values {
      margin: 30px 0 20px;
      padding: 10px 20px;
      font-size: 14px;
      line-height: 20px;
      width: auto;
    }

    .contacts {
      display: block;
      width: auto;

      li {
        margin-bottom: 10px;
        padding-top: 10px;
        padding-left: 20px;
        font-size: 14px;
        line-height: 20px;

        &::before {
          top: 16px;
          left: 0;
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 24' width='16' height='16'%3E%3Cg fill='%23fff'%3E%3Cpath d='M10.107 24h1.786l6.885-6.439a9.822 9.822 0 0 0 0-14.548A11.324 11.324 0 0 0 11 0a11.324 11.324 0 0 0-7.778 3.013 9.822 9.822 0 0 0 0 14.548zm-5.1-19.317A8.723 8.723 0 0 1 11 2.362a8.724 8.724 0 0 1 5.992 2.321 7.567 7.567 0 0 1 0 11.208L11 21.495l-5.992-5.604a7.567 7.567 0 0 1 0-11.208z'/%3E%3Cpath d='M11 15.627a5.879 5.879 0 0 0 4.038-1.564 5.105 5.105 0 0 0 0-7.552 6.002 6.002 0 0 0-8.076 0 5.105 5.105 0 0 0 0 7.552A5.88 5.88 0 0 0 11 15.627zM8.153 7.624a4.232 4.232 0 0 1 5.694 0 3.6 3.6 0 0 1 0 5.326 4.227 4.227 0 0 1-5.694 0 3.6 3.6 0 0 1 0-5.326z'/%3E%3C/g%3E%3C/svg%3E");
        }

        &.tel {
          width: 260px;
        }

        & + li {
          margin-left: 0;
        }

        h4 {
          color: #fff;
          line-height: 24px;
          font-weight: bold;
          line-height: 28px;
        }
      }
    }
  }

  .aboutLayout {
    max-width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .about {
    margin: 28px 0 72px 28px;

    h3 {
      font-size: 40px;
      color: #e83e0b;
      line-height: 58px;
    }

    .con {
      position: relative;
      padding: 48px 20px 80px 36px;
      font-size: 12px;
      line-height: 16px;
      border-width: 2px 0 2px 2px;

      p {
        padding: 4px 0;
      }

      .dot {
        top: 6px;
        left: 23px;

        &, &::before, &::after {
          width: 9px;
          height: 9px;
        }

        &::before {
          left: -15px;
        }

        &::after {
          right: -15px;
        }
      }

      &::after {
        left: 36px;
        bottom: -26px;
        width: 140px;
        height: 90px;
      }
    }
  }

  .history {
    margin: 0 0 40px -2px;
    padding-top: 12px;
    width: 100%;
    transform: translateX(50%);

    h3 {
      margin-bottom: 0;
      transform: translateX(-40%);
      font-size: 30px;
      line-height: 38px;
    }
  }

  .timeline {
    margin-top: 20px;
    padding-right: 16px;
    width: 50%;
    border-left-width: 2px;

    &::after {
      width: 188px;
      height: 66px;
    }

    & > li {
      width: 100%;
      padding-left: 20px;
      font-size: 12px;
      line-height: 16px;

      &.main {
        margin-top: 0;
        padding-left: 0;
        margin-left: -1px;
        padding-bottom: 0;

        &::before, &::after {
          display: none;
        }

        h4 {
          margin-left: -52px;
          background-color: #fff;
        }

        .points {
          margin-top: 0;
          margin-left: -1px;
          padding: 60px 0 1px;
          border-left: 2px solid #e83e0b;

          li {
            position: relative;
            margin-top: -40px;
            padding-left: 20px;

            &.inset {
              &::before {
                left: 20px;
                top: -33px;
                width: 86px;
                height: 42px;
              }

              .t {
                padding-left: 90px;

                &::after {
                  width: 105px;
                }
              }
            }

            &:last-child {
              &::before {
                display: none;
              }
            }

            .t {
              strong {
                font-size: 23px;
              }

              &::before {
                margin-top: -6px;
                left: -27px;
                width: 12px;
                height: 12px;
                border-width: 2px;
              }

              &::after {
                margin-top: -1px;
                left: -15px;
                width: 12px;
                height: 2px;
              }
            }
            .txt {
              min-height: 60px;
            }
            &:nth-child(2n - 1) {
              margin-left: -100%;
              padding-left: 0;
              width: calc(100% - 20px);

              .t {
                text-align: right;

                &::before {
                  left: calc(100% + 13px);
                }
                &::after {
                  left: calc(100% + 2px);
                }
              }
            }
          }
        }
      }

      h4 {
        height: 24px;
        font-size: 14px;
        line-height: 24px;

        strong {
          font-size: 23px;
        }

        &::before {
          margin-top: -4px;
          left: -27px;
          width: 12px;
          height: 12px;
          border-width: 2px;
        }

        &::after {
          margin-top: 1px;
          left: -16px;
          width: 12px;
          height: 2px;
        }
      }

      .points {
        margin-top: 6px;

        li {
          margin-bottom: 8px;

          .t {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      & + li {
        margin-top: 0px;
      }

      &:nth-child(3) {
        margin-top: -160px;
      }
      &:nth-child(4) {
        margin-top: -28px;
      }

      &:nth-child(2n) {
        margin-left: calc(-100% - 12px);
        padding-left: 0;
        width: calc(100% - 8px);

        h4 {
          &::before {
            left: calc(100% + 13px);
          }
          &::after {
            left: calc(100% + 2px);
          }
        }
      }
    }
  }

  .map {
    padding: 80px 0 60px;
    width: 100%;
    height: auto;
    overflow: hidden;
    background: #e83e0b url(https://alihtml.juzhen.cn/juzhen/assets/img/about/m/layout.jpg) no-repeat 50% 130px;
    background-size: contain;

    .hd {
      position: static;
      margin: 0 0 32px;
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      color: #fff;

      h3 {
        font-size: 30px;
        line-height: 34px;
        color: #fff;
      }
    }

    .places {
      position: static;
      margin: 140px 0 0;
      padding: 0 24px;
      height: auto;
      width: 100%;
      transform: none;
      column-count: 2;
      column-gap: 36px;
      
      li {
        position: static;
        width: 100%;
        margin: 0;
        min-height: auto;
        text-align: left;

        dl {
          display: inline-block;
          margin-bottom: 18px;
          font-size: 12px;
          line-height: 16px;
          color: #fff;

          &::before, &::after {
            display: none;
          }

          dt {
            margin-bottom: 2px;
            font-size: 16px;
            line-height: 22px;
          }

          h4 {
            font-size: 12px;
            line-height: 16px;
          }

        }
      }
    }
  }

  .ecology {
    margin: 48px auto 60px;
    padding: 0;
    font-size: 12px;
    line-height: 16px;

    h3 {
      font-size: 26px;
      line-height: 62px;
    }  

    ul {
      padding-top: 24px;

      &::before {
        top: 0;
        left: 50%;
        margin-left: -2px;
        width: 5px;
        height: 5px;
        box-shadow: 
          0 22px 0 0 rgba($color: #e83e0b, $alpha: 1),
          0 22px 0 2px rgba($color: #e83e0b, $alpha: .44),
          0 22px 0 4px rgba($color: #e83e0b, $alpha: .33);
      }
      &::after {
        top: 2px;
        width: 1px;
        height: 22px;
      }

      li {
        padding: 26px 8px 0;

        &::before {
          top: 18px;
          margin-left: -3px;
          width: 5px;
          height: 5px;
        }
        &::after {
          height: 20px;
        }

        &:first-child {
          &::before {
            margin-left: -2px;
          }
        }

        h4 {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .factoryBanner {
    padding-top: 180px;
    height: 640px;
    font-size: 14px;
    line-height: 20px;

    h2 {
      margin-bottom: 40px;
      font-size: 50px;
      line-height: 60px;
    }

    p {
      margin-top: -20px;
      height: auto;
      padding: 0 20px;
    }

    .img {
      margin: 0;
      width: 100%;
      height: auto;
    }
  }

  .box {
    font-size: 14px;
    line-height: 20px;

    &::before {
      display: none;
    }

    .layout {
      padding: 0;
    }

    h3 {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .factory {
    padding: 0;

    .factoryMap {
      position: static;
      display: block;
      width: 100%;
      height: auto;
    }

    .summary {
      margin-left: 0;
      padding: 24px;

      p {
        margin: 8px 0 12px;
      }
    }
  }

  .intro {
    padding: 24px;

    p {
      text-indent: 2em;
    }

    .workshops {
      margin-top: 12px;

      li {
        flex-direction: column-reverse;

        & + li {
          margin-top: 12px;
        }

        &:nth-child(2n) {
          flex-direction: column-reverse;

          .con {
            margin: 0;
          }
        }

        .con {
          display: flex;
          padding: 12px 16px;
          margin-left: 0;
          width: auto;

          h4 {
            margin-bottom: 4px;
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
          }

          p {
            text-indent: 0;
          }
        }
      }
    }
  }

  .bannerEnd {
    margin-bottom: 0;

    .layout {
      display: block;
    }

    .summary {
      position: relative;
      top: 0;
      left: 0;
      padding: 24px;
      height: 260px;
      max-width: 80%;
    }

    h3 {
      margin-bottom: 8px;
    }

    .img {
      margin-top: -260px;
      min-height: 260px;
    }
  }
}
</style>
